import React, { useState } from 'react';
import type { ReactElement } from 'react';

import {
  Card,
  CardContent,
  CardHeader,
  Skeleton,
  ButtonGroup,
  Button,
} from '@mui/material';
import { Stack } from '@mui/system';
import useLocalStorageState from 'use-local-storage-state';

import EventCard from '../components/cards/EventCard';
import { Sport } from '../generated/graphql';
import useGetEventsForHomeFeed from '../hooks/queries/useGetEventsForHomeFeed';
import useAuthUser from '../hooks/useAuthUser';
import { useLogin } from '../hooks/useLogin';
import BasicPage from '../layouts/BasicPage';
import MainLayout from '../layouts/MainLayout';
import { prettySportNames } from '../utils/enums';

function HomePage() {
  const { user, isLoading: isUserLoading } = useAuthUser();
  const { login } = useLogin('/');
  const [selectedSport, setSelectedSport] = useLocalStorageState(
    'selectedSport',
    {
      defaultValue: Sport.VballSand,
    }
  );

  // State for filtering events
  const [filter, setFilter] = useState<'upcoming' | 'just_added' | 'previous'>(
    'upcoming'
  );

  const { data, isLoading: isLoadingEvents } = useGetEventsForHomeFeed(
    selectedSport,
    user?.sub,
    filter
  );

  const isLoading = isUserLoading || isLoadingEvents;
  const selectedSportName = prettySportNames.find(
    item => item.type === selectedSport
  );
  const events = data?.events || [];

  // Filter logic for upcoming, just added, and previous
  const now = new Date();
  const oneWeekAgo = new Date(now);
  oneWeekAgo.setDate(now.getDate() - 7);

  const filteredEvents = events.filter(event => {
    if (filter === 'upcoming') {
      return new Date(event.startDate) >= now;
    }
    if (filter === 'just_added') {
      return new Date(event.createdAt) >= oneWeekAgo;
    }
    if (filter === 'previous') {
      return new Date(event.startDate) < now;
    }
    return true;
  });

  return (
    <BasicPage title="Home">
      {/* Filter Buttons */}
      <ButtonGroup
        variant="outlined"
        color="primary"
        aria-label="event filter buttons"
      >
        <Button
          onClick={() => setFilter('upcoming')}
          variant={filter === 'upcoming' ? 'contained' : 'outlined'}
        >
          future events
        </Button>
        <Button
          onClick={() => setFilter('just_added')}
          variant={filter === 'just_added' ? 'contained' : 'outlined'}
        >
          recently added
        </Button>
        <Button
          onClick={() => setFilter('previous')}
          variant={filter === 'previous' ? 'contained' : 'outlined'}
        >
          past events
        </Button>
      </ButtonGroup>

      <Stack spacing={2} sx={{ mt: 2 }}>
        {isLoading &&
          Array.from({ length: 6 }).map((_, index) => (
            <Skeleton key={index} variant="rounded" height={80} />
          ))}
        {!isLoading &&
          filteredEvents.map(event => {
            return (
              <React.Fragment key={event.id}>
                <EventCard event={event} user={user} showInvite />
              </React.Fragment>
            );
          })}
        {!isLoading && filteredEvents.length === 0 && (
          <Card>
            <CardHeader title={`No ${selectedSportName?.name} events found`} />
            <CardContent>
              Try selecting a different sport or filter.
            </CardContent>
          </Card>
        )}
      </Stack>
    </BasicPage>
  );
}

HomePage.getLayout = function getLayout(page: ReactElement) {
  return <MainLayout>{page}</MainLayout>;
};

export default HomePage;
