import React from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Breadcrumbs, Container, IconButton, Skeleton } from '@mui/material';
import { useRouter } from 'next/router';

import LoginButton from '../components/buttons/LoginButton';
import useAuthUser from '../hooks/useAuthUser';

interface BasicPageProps {
  title?: string;
  requiresAuth?: boolean;
  showBreadcrumb?: boolean;
  children?: React.ReactNode;
  // priveleged access for the founders
  requiresSuperAdmin?: boolean;
}

const BasicPage = ({
  title,
  requiresAuth,
  requiresSuperAdmin,
  showBreadcrumb,
  children,
}: BasicPageProps) => {
  const { user, isLoading } = useAuthUser();
  const router = useRouter();
  const handleBack = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    router.back();
  };
  let isAuthorized = true;
  if (requiresSuperAdmin) {
    isAuthorized = !!user && user.isSuperAdmin;
  } else if (requiresAuth) {
    isAuthorized = !!user;
  }

  return (
    <Container maxWidth="sm">
      {showBreadcrumb && (
        <Breadcrumbs aria-label="breadcrumb">
          <IconButton onClick={handleBack} aria-label="go back">
            <ArrowBackIcon />
          </IconButton>
        </Breadcrumbs>
      )}
      {title && (
        <h1>
          {title.toLowerCase()}
          {requiresSuperAdmin ? (
            <span style={{ color: 'red' }}> (admin only)</span>
          ) : (
            ''
          )}
        </h1>
      )}
      {isLoading && <Skeleton variant="rounded" height={600} />}
      {!isAuthorized && !isLoading && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            backdropFilter: 'blur(8px)',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
          }}
        >
          <h3>Please log in to view this page</h3>
          <LoginButton />
        </div>
      )}
      {isAuthorized && !isLoading && children}
    </Container>
  );
};

export default BasicPage;
