import { useQuery } from '@tanstack/react-query';

import { EventPrivacy, SortOrder, Sport } from '../../generated/graphql';
import useGql from '../useGql';

const useGetEventsForHomeFeed = (
  sport: Sport,
  userId: string | undefined,
  filter: 'upcoming' | 'just_added' | 'previous'
) => {
  const { gqlSdk } = useGql();
  const now = new Date();
  const oneWeekAgo = new Date(now);
  oneWeekAgo.setDate(now.getDate() - 7);

  // Determine the dynamic orderBy clause based on the filter
  const orderBy =
    filter === 'upcoming'
      ? [{ startDate: SortOrder.Asc }] // Upcoming: order by startDate ascending
      : filter === 'just_added'
      ? [{ createdAt: SortOrder.Desc }] // Just Added: order by createdAt descending
      : [{ startDate: SortOrder.Desc }]; // Previous: order by startDate descending

  return useQuery({
    queryKey: ['upcomingEvents', sport, userId, filter],
    queryFn: () =>
      gqlSdk?.getEventsForHomeFeed({
        where: {
          sport: {
            equals: sport,
          },
          AND: [
            userId
              ? {
                  OR: [
                    {
                      privacy: {
                        in: [EventPrivacy.Public, EventPrivacy.ViewOnly],
                      },
                    },
                    {
                      createdByUserId: {
                        equals: userId,
                      },
                    },
                    {
                      invitedUsers: {
                        some: { userId: { equals: userId } },
                      },
                      privacy: {
                        notIn: [EventPrivacy.Public, EventPrivacy.ViewOnly],
                      },
                    },
                  ],
                }
              : {
                  privacy: {
                    in: [EventPrivacy.Public, EventPrivacy.ViewOnly],
                  },
                },
            // Filter condition for upcoming, just added, and previous
            filter === 'upcoming'
              ? { startDate: { gte: now.toISOString() } }
              : filter === 'just_added'
              ? { createdAt: { gte: oneWeekAgo.toISOString() } }
              : { startDate: { lt: now.toISOString() } }, // previous
          ],
        },
        orderBy, // Dynamic orderBy based on the selected filter
      }),
    enabled: !!gqlSdk,
  });
};

export default useGetEventsForHomeFeed;
